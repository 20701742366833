import { ICellRendererParams } from 'ag-grid-community';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Work, getHeadersRes, monthlyChart } from '@/api/ksg/ksg.def';

import { EditChartCell } from '@/components/EditChartCell/EditChartCell';
import {
    getFactTypographyProps,
    getRemainedVolumeTooltipProps,
    isLessThanVolumeTotal,
} from '@/components/EditChartCell/EditChartCell.service';

import { DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE, PERMISSIONS } from '@/shared/rolePermissions';

import { TChangeViewType, cpgViewEnum, cpgViewSelector } from '@/store/slices/cpgViewSlice';
import { TPriceType, priceSettingsSelector } from '@/store/slices/priceSettings';
import { profileSelector } from '@/store/slices/profileSlice';
import { getSettings } from '@/store/slices/settings/settingsViewAgGrid';
import { useTypedSelector } from '@/store/store';

import { theme } from '@/styles/theme';

import '../../AgGrid.scss';
import UpdateKs3Ag from '../UpdateKs3Ag';
import { getSaldoBetweenMonthlyChartValueAndVolumeTotal, submitKSGMonthlyChart } from './MonthRenderer.service';
import { DeletePlanDialog } from './components/DeletePlanDialog';

export default function MonthRenderer({
    headers,
    setMutationsLoading,
    cellRendererParams,
    chartIndex,
    isActive,
}: {
    headers: getHeadersRes | undefined;
    setMutationsLoading: Dispatch<SetStateAction<boolean>>;
    cellRendererParams: ICellRendererParams<Work, any, any>;
    chartIndex: number;
    isActive?: boolean;
}) {
    const { cpgView } = useTypedSelector(cpgViewSelector);
    const { profile } = useTypedSelector(profileSelector);
    const { priceSettings } = useTypedSelector(priceSettingsSelector);
    const { projectId } = useParams();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    /* Получение данныс с стора о базовом плане и верификационном факте */
    const { basicPlan, verificationPlan } = useTypedSelector(getSettings);

    const isVolumeFloatedToParent = cellRendererParams.data?.unit && cellRendererParams.data?.hasChildren;
    const isChild = !cellRendererParams.data?.hasChildren && cellRendererParams.data?.level !== 0;

    const isCreatedMSG = !!headers?.allHeaders[chartIndex]?.isCreatedMSG;
    const year = cellRendererParams.data?.monthlyCharts[chartIndex]?.year || 0;
    const month = cellRendererParams.data?.monthlyCharts[chartIndex]?.month || 0;
    const isPreviousMonth =
        (cellRendererParams.data?.monthlyCharts[chartIndex]?.month || 0) < new Date().getMonth() + 1;
    const isPreviousYear = (cellRendererParams.data?.monthlyCharts[chartIndex]?.year || 0) < new Date().getFullYear();

    const CASH_PRICE_KEYS = {
        plan: {
            er: 'costPlan',
            gk: 'costPlanGK',
            sub: 'costPlanSub',
        },
        fact: {
            er: 'costFact',
            gk: 'costFactGK',
            sub: 'costFactSub',
        },
        basePlan: {
            er: 'costBasePlan',
            gk: 'costBasePlanGK',
            sub: 'costBasePlanSub',
        },
    } as { [key in monthlyChart as string]: Record<TPriceType, keyof monthlyChart> };

    const CHART_KEYS: Record<TChangeViewType, { [key in monthlyChart as string]: keyof monthlyChart }> = {
        blank: {
            plan: 'plan',
            fact: 'fact',
            basePlan: 'basePlan',
            verifiedFact: 'verifiedFact',
        },
        cash: {
            plan: CASH_PRICE_KEYS.plan[priceSettings.ksg.plan as TPriceType],
            fact: CASH_PRICE_KEYS.fact[priceSettings.ksg.fact as TPriceType],
            basePlan: CASH_PRICE_KEYS.basePlan[priceSettings.ksg.base as TPriceType],
            verifiedFact: 'costVerFact',
        },
        digger: {
            plan: 'hourPlan',
            fact: 'hourFact',
            basePlan: 'hourBasePlan',
            verifiedFact: 'hourVerFact',
        },
    };

    useEffect(() => {}, [cpgView]);

    if (cpgView === cpgViewEnum.blank && cellRendererParams.data?.hasChildren && cellRendererParams.data?.level === 0) {
        return (
            <Fragment>
                <UpdateKs3Ag
                    setMutationsLoading={setMutationsLoading}
                    charts={cellRendererParams.data?.monthlyCharts}
                    idx={chartIndex}
                />
            </Fragment>
        );
    }

    return isVolumeFloatedToParent || isChild || cpgView !== 'blank' ? (
        <div className='month_container'>
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={CHART_KEYS[cpgView].plan}
                chartIndex={chartIndex}
                defaultValue={
                    cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[chartIndex][
                        CHART_KEYS[cpgView].plan
                    ]
                }
                TypographyProps={() => ({
                    color: theme.palette.primary.main,
                })}
                onSubmitData={(args) => {
                    const { formData, methods } = args;
                    if (
                        formData.plan == null &&
                        (cellRendererParams.data?.monthlyCharts[chartIndex].hasMSG ||
                            cellRendererParams.data?.monthlyCharts[chartIndex].fact)
                    ) {
                        methods.reset();
                        setIsDialogOpen(true);
                    } else {
                        submitKSGMonthlyChart(args);
                    }
                }}
                Dialog={(args) => {
                    return (
                        <DeletePlanDialog
                            {...args}
                            isDialogOpen={isDialogOpen}
                            setIsDialogOpen={setIsDialogOpen}
                        />
                    );
                }}
                isEditable={
                    PERMISSIONS.ksg.smr.plan.roles.includes(profile.role) &&
                    PERMISSIONS.ksg.smr.plan.cpgViews.includes(cpgView) &&
                    isChild
                }
                rules={{
                    max: undefined,
                    validate: {
                        isLessThanVolumeTotal: (value) =>
                            isLessThanVolumeTotal(
                                value,
                                getSaldoBetweenMonthlyChartValueAndVolumeTotal(
                                    value,
                                    cellRendererParams,
                                    CHART_KEYS[cpgView].plan
                                ),
                                'Опер. план'
                            ),
                    },
                }}
                TooltipProps={(value) =>
                    getRemainedVolumeTooltipProps(
                        getSaldoBetweenMonthlyChartValueAndVolumeTotal(
                            value,
                            cellRendererParams,
                            CHART_KEYS[cpgView].plan
                        )
                    )
                }
                isActive={isActive}
            />
            <EditChartCell
                cellRendererParams={cellRendererParams}
                chartValueKey={CHART_KEYS[cpgView].fact}
                chartIndex={chartIndex}
                TypographyProps={(value) =>
                    getFactTypographyProps(value, cellRendererParams, chartIndex, 'monthlyCharts')
                }
                isEditable={false}
                defaultValue={
                    cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[chartIndex][
                        CHART_KEYS[cpgView].fact
                    ]
                }
                isActive={isActive}
            />
            {basicPlan && (
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={CHART_KEYS[cpgView].basePlan}
                    chartIndex={chartIndex}
                    defaultValue={
                        cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[chartIndex][
                            CHART_KEYS[cpgView].basePlan
                        ]
                    }
                    onSubmitData={submitKSGMonthlyChart}
                    rules={{
                        max: undefined,
                        validate: {
                            isLessThanVolumeTotal: (value) =>
                                isLessThanVolumeTotal(
                                    value,
                                    getSaldoBetweenMonthlyChartValueAndVolumeTotal(
                                        value,
                                        cellRendererParams,
                                        CHART_KEYS[cpgView].basePlan
                                    ),
                                    'Базовый план'
                                ),
                        },
                    }}
                    isEditable={
                        PERMISSIONS.ksg.smr.basePlan.roles.includes(profile.role) &&
                        PERMISSIONS.ksg.smr.basePlan.cpgViews.includes(cpgView) &&
                        isChild
                    }
                    TooltipProps={(value) =>
                        getRemainedVolumeTooltipProps(
                            getSaldoBetweenMonthlyChartValueAndVolumeTotal(
                                value,
                                cellRendererParams,
                                CHART_KEYS[cpgView].basePlan
                            )
                        )
                    }
                    isActive={isActive}
                />
            )}
            {verificationPlan && (
                <EditChartCell
                    cellRendererParams={cellRendererParams}
                    chartValueKey={CHART_KEYS[cpgView].verifiedFact}
                    chartIndex={chartIndex}
                    defaultValue={
                        cellRendererParams.api.getValue('monthlyCharts', cellRendererParams.node)[chartIndex][
                            CHART_KEYS[cpgView].verifiedFact
                        ]
                    }
                    rules={{
                        max: undefined,
                    }}
                    isEditable={
                        !isCreatedMSG &&
                        (isPreviousYear || isPreviousMonth) &&
                        isChild &&
                        PERMISSIONS.ksg.smr.verifiedFact.roles.includes(profile.role) &&
                        DOES_ROLE_HAS_ACCESS_TO_PROJECT_TABLE(projectId as string, profile, 'smr') &&
                        PERMISSIONS.ksg.smr.verifiedFact.cpgViews.includes(cpgView)
                    }
                    isActive={isActive}
                    onSubmitData={submitKSGMonthlyChart}
                />
            )}
        </div>
    ) : (
        <div className='grid_center'></div>
    );
}
