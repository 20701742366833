import AddIcon from '@mui/icons-material/Add';
import HeightIcon from '@mui/icons-material/Height';
import { Box, IconButton, Stack } from '@mui/material';
import {
    CellClassParams,
    EditableCallbackParams,
    ICellEditorParams,
    ICellRendererParams,
    IHeaderParams,
    ValueGetterParams,
    ValueSetterParams,
} from 'ag-grid-community';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

import { Work, WorkOrWorkPosition } from '@/api/ksg/ksg.def';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UIHeaderFilter } from '@/components/ui/UIHeaderFilter';

import {
    canEditableByPredecessors,
    getHeadersMonths,
    getOfferColDefs,
    RD_STATUS_OPTIONS,
    VOLUME_TOTAL_CELL_EDITOR_PARAMS,
} from '@/pages/AgGrid/AgGrid.service';
import { StyledCheckbox } from '@/pages/AgGrid/StyledCheckbox';
import { DateCellRenderer } from '@/pages/AgGrid/components/DateCellRenderer';
import { HeaderNameEl } from '@/pages/AgGrid/components/HeaderRenderer';
import { LevelRenderer } from '@/pages/AgGrid/components/LevelRenderer';
import MonthRenderer from '@/pages/AgGrid/components/MonthRenderer/MonthRenderer';
import { NotificatorContainer } from '@/pages/AgGrid/components/NotificatorContainer/NotificatorContainer';
import RowMonthHeaderRenderer from '@/pages/AgGrid/components/RowMonthHeaderRenderer';
import { TaskModeCell } from '@/pages/AgGrid/components/TaskModeCell/TaskModeCell';
import { WorkPositionLevelRenderer } from '@/pages/AgGrid/components/WorkPositionLevelRenderer';
import type { TAgGridContext } from '@/pages/KSGTable';
import { UserRolesEn } from '@/pages/Users/user';
import { COL_DEF_TEMPLATE } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { getDefaultValidationRules } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.service';
import { NumberCellRenderer } from '@/pages/WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';
import { PercentageCellRenderer } from '@/pages/WorkManagment/components/CellRenderers/PercentageCellRenderer';

import { RelationsIconNew } from '@/shared/SVG/Svg';
import { HtmlTooltip } from '@/shared/components/HtmlTooltip';
import { isWorkPosition } from '@/shared/guards/works.guards';
import { DOES_ROLE_HAS_ACCESS_TO_FEATURE } from '@/shared/rolePermissions';
import { tooltipHelperNumber } from '@/shared/utils';
import { hasParam } from '@/shared/utils/common.utils';

import { DateCellRendererProps } from '../../AgGrid/components/DateCellRenderer';
import { OperationalDateNotificator } from '../../AgGrid/components/OperationalDateNotificator';
import { IGetKSGColDefProps, TKSGColDef } from '../KSGTable.types';
import { VolumeTotalCellRenderer } from '../components/CellRenderer/VolumeTotalCellRenderer';
import { getOptionalDateValidation, isAfterStartDate, isBeforeEndDate } from './dateValidation.utils';
import { getOptionalNumberValidation } from './numberValidation.utils';

// TODO:
// 1. Почистить пропсы
export const getColDefs = ({
    integrationStatus,
    isVisibleGantt,
    setAddLevelData,
    setIsOpenAddLevel,
    location,
    columnWidths,
    isOfferActive,
    profile,
    gridRef,
    setRelationsOpened,
    setRelationData,
    headersCPG,
    costDoneMode,
    setMutationsLoading,
    handleHeightChange,
    refetchCollectionOfFactOfAct,
}: IGetKSGColDefProps): TKSGColDef => {
    const dataColumn = [
        {
            headerName: 'Уровень',
            field: 'level',
            width: columnWidths.get('level') || 72,
            colId: 'level',
            spanHeaderHeight: true,
            headerComponent: () =>
                DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_WORK_KSG') && (
                    <Box mx='auto'>
                        {profile.role !== 'client' && (
                            <IconButton
                                onClick={() => {
                                    setAddLevelData({
                                        id: null,
                                        index: 1,
                                        level: 1,
                                    });
                                    setIsOpenAddLevel(true);
                                }}
                                color='primary'
                                component='label'
                                sx={{
                                    padding: '4px',
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        fontSize: 16,
                                    }}
                                />
                            </IconButton>
                        )}
                    </Box>
                ),
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, TAgGridContext>) => {
                if (!params.data) return <></>;

                if (isWorkPosition(params.data)) return <WorkPositionLevelRenderer />;

                const data = params.data as Work;

                return (
                    <LevelRenderer
                        data={data}
                        integrationStatus={integrationStatus}
                        onDeleteRow={params.context.onDeleteRow}
                        isOfferActive={isOfferActive}
                        onRowHideToggle={params.context.updateHiddenRows({
                            id: data.id,
                            code: data.code,
                        })}
                        setAddWorkId={() => {
                            if (!data) return;

                            setAddLevelData({
                                id: data.id,
                                index: params.rowIndex + 1,
                                level: data.level !== null ? data.level + 1 : data.level,
                            });

                            setIsOpenAddLevel(true);
                        }}
                        arrowDown={params.context.isRowHidden(data.id)}
                        refetchCollectionOfFactOfAct={refetchCollectionOfFactOfAct}
                    />
                );
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: columnWidths.get('numOrder') || 50,
            spanHeaderHeight: true,
            headerName: '№',
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                return <div>{data.value}</div>;
            },
        },
        {
            field: 'code',
            colId: 'code',
            width: columnWidths.get('code') || 90,
            spanHeaderHeight: true,
            headerName: 'ID',
            valueGetter: (params: ValueGetterParams<WorkOrWorkPosition>) => params.data?.id,
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, TAgGridContext>) => {
                if (isWorkPosition(params.data))
                    return (
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1 } }}
                        >
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    );

                const data = params.data as Work;

                return (
                    <Link
                        to={`/workManagment/${params.context.projectId}/${data?.id}/estimate/resources`}
                        state={{ prevPath: location.pathname }}
                    >
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1, cursor: 'pointer' } }}
                        >
                            {data.id}
                        </OverflowableTypographyWithTooltip>
                    </Link>
                );
            },
        },
        {
            field: 'tag',
            colId: 'tag',
            width: columnWidths.get('tag') || 124,
            spanHeaderHeight: true,
            headerName: 'Прочие работы',
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, TAgGridContext>) => {
                if (isWorkPosition(params.data)) return null;

                const data = params.data as Work;

                return (
                    <div>
                        {!data?.hasChildren && (
                            <StyledCheckbox
                                checked={params.value}
                                onChange={(e) => {
                                    DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'EDIT_WORK_KSG') &&
                                        params.context.onToggleTag(e.target.checked, data.id);
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            field: 'mainGroup',
            colId: 'mainGroup',
            width: columnWidths.get('mainGroup') || 90,
            spanHeaderHeight: true,
            headerName: 'Ключевые объемы',
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                validation: {
                    required: false,
                },
            }),
            cellClass: (params: CellClassParams) => !params.data?.hasChildren && 'ag-cell-editable',
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('mainGroup'),
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: columnWidths.get('objTitle') || 260,
            headerName: 'Титул',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    width={params.column.getActualWidth()}
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('objTitle')}
                    onClick={() => params.context.openFilterDialog('objTitle', 'objTitle')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objTitle'),
        },
        {
            field: 'objName',
            colId: 'objName',
            width: columnWidths.get('objName') || 260,
            headerName: 'Объект',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('objName')}
                    onClick={() => params.context.openFilterDialog('objName', 'objName')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objName'),
        },
        {
            field: 'rdGroup',
            colId: 'rdGroup',
            width: columnWidths.get('rdGroup') || 90,
            headerName: 'Раздел РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdGroup'),
        },
        {
            field: 'rdCode',
            colId: 'rdCode',
            width: columnWidths.get('rdCode') || 90,
            headerName: 'Шифр РД',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('rdCode')}
                    onClick={() => params.context.openFilterDialog('rdCode')}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdCode'),
        },
        {
            field: 'rdStatus',
            colId: 'rdStatus',
            width: columnWidths.get('rdStatus') || 190,
            headerName: 'Статус выдачи РД',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('rdStatus')}
                    onClick={() => params.context.openFilterDialog('rdStatus', 'rdStatus')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return params.data?.level !== 0;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.AUTOCOMPLETE,
            cellEditorParams: {
                dataType: 'autocomplete',
                options: RD_STATUS_OPTIONS,
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdStatus'),
        },
        {
            field: 'rdDateApproval',
            colId: 'rdDateApproval',
            width: columnWidths.get('rdDateApproval') || 140,
            headerName: 'Дата согласования РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                field: 'rdDateApproval',
                gridApi: gridRef.current?.api,
                cellClass: 'ag-cell-date',
                validation: getOptionalDateValidation(),
            }),
        },
        {
            field: 'rdVersion',
            colId: 'rdVersion',
            width: columnWidths.get('rdVersion') || 90,
            headerName: 'Версия РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER('rdVersion'),
            cellEditorParams: {
                NumericFormatProps: {
                    decimalScale: 0,
                },
                noValuePlug: '',
                validation: getOptionalNumberValidation(),
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('rdVersion'),
        },
        {
            field: 'rdDate',
            colId: 'rdDate',
            width: columnWidths.get('rdDate') || 140,
            headerName: 'Дата выдачи РД',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                field: 'rdDate',
                gridApi: gridRef.current?.api,
                cellClass: 'ag-cell-date',
                validation: getOptionalDateValidation(),
            }),
        },
        {
            field: 'typeOfWorks',
            colId: 'typeOfWorks',
            width: columnWidths.get('typeOfWorks') || 140,
            headerName: 'Конструктив (вид работ)',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('typeOfWorks'),
            cellEditorParams: {
                ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA().cellEditorParams,
                validation: {
                    ...getDefaultValidationRules('text'),
                    required: false,
                },
            },
        },
        {
            field: 'workGroup',
            colId: 'workGroup',
            width: columnWidths.get('workGroup') || 256,
            headerName: 'Группа работ',
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('workGroup')}
                    onClick={() => params.context.openFilterDialog('workGroup')}
                />
            ),
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('workGroup'),
        },
        {
            field: 'groupOfWorks',
            colId: 'groupOfWorks',
            width: columnWidths.get('groupOfWorks') || 256,
            headerName: 'Головная группа работ',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('groupOfWorks'),
        },
        {
            field: 'contractorCompany',
            colId: 'contractorCompany',
            width: columnWidths.get('contractorCompany') || 256,
            headerName: 'Подрядчик',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('contractorCompany')}
                    onClick={() => params.context.openFilterDialog('contractorCompany')}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
                validation: {
                    required: false,
                },
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('contractorCompany'),
        },
        {
            field: 'workName',
            colId: 'workName',
            width: columnWidths.get('workName') || 256,
            headerName: 'Наименование работы',
            spanHeaderHeight: true,
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    width={params.column.getActualWidth()}
                    name={params.displayName}
                    isApplied={params.context.appliedFilters.includes('workName')}
                    onClick={() => params.context.openFilterDialog('workName', 'workName')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return true;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.TEXTAREA({
                cellClass: 'ag-cell-align-left',
                maxRows: 2,
            }),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('workName'),
        },
        ...getOfferColDefs('lsrCiphers', isOfferActive),
        {
            field: 'unit',
            colId: 'unit',
            width: columnWidths.get('unit') || 90,
            headerName: 'Ед. измерения',
            headerTooltip: 'Единица измерения',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.STRING,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('unit'),
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: columnWidths.get('volumeTotal') || 124,
            headerName: 'Общий объём',
            spanHeaderHeight: true,
            // TODO:
            // 1. Вынести все headerComponent с фильтрами в отдельный компонент
            headerComponent: (params: IHeaderParams<Work, TAgGridContext>) => (
                <UIHeaderFilter
                    name={params.displayName}
                    width={params.column.getActualWidth()}
                    isApplied={params.context.appliedFilters.includes('volumeTotal')}
                    onClick={() => params.context.openFilterDialog('volumeTotal')}
                    containerPadding={'0 6px 0 0'}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params?.data?.hasChildren && (['admin', 'scheduler'] as UserRolesEn[]).includes(profile.role);
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('volumeTotal'),
            cellEditorParams: VOLUME_TOTAL_CELL_EDITOR_PARAMS,
            cellRenderer: VolumeTotalCellRenderer,
        },
        {
            headerName: 'Выполненный объём работ',
            colId: 'volumeDone',
            children: [
                {
                    field: 'volumeDonePlan',
                    width: columnWidths.get('volumeDonePlan') || 82,
                    headerName: 'План',
                    cellRenderer: (params: ICellRendererParams<Work>) =>
                        (params.data?.volumeDonePlan || !params?.data?.hasChildren) && (
                            <NumberCellRenderer {...params} />
                        ),
                },
                {
                    field: 'volumeDoneFact',
                    width: columnWidths.get('volumeDoneFact') || 82,
                    headerName: 'Факт',
                    cellRenderer: (params: ICellRendererParams<Work>) =>
                        (params.data?.volumeDoneFact || !params?.data?.hasChildren) && (
                            <NumberCellRenderer {...params} />
                        ),
                },
            ],
        },
        {
            headerName: 'Базовый план',
            colId: 'basePlanDates',
            children: [
                {
                    field: 'startDate',
                    colId: 'startDate',
                    width: columnWidths.get('startDate') || 82,
                    headerName: 'Начало',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if (isWorkPosition(params.data)) return false;
                        return !params.data?.hasChildren;
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'startDate',
                        gridApi: gridRef.current?.api,
                        cellClass: 'ag-cell-padding-none ag-cell-flex ag-cell-date',
                        validation: getOptionalDateValidation({
                            isBeforeEndDate: isBeforeEndDate(gridRef.current?.api, 'endDate'),
                        }),
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <NotificatorContainer
                                isVisible={params.data?.basePlanePeriodAlert}
                                title='Даты не совпадают с чартами КСГ'
                            >
                                {params?.data?.startDate}
                            </NotificatorContainer>
                        );
                    },
                },
                {
                    field: 'endDate',
                    colId: 'endDate',
                    width: columnWidths.get('endDate') || 82,
                    headerName: 'Окончание',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if (isWorkPosition(params.data)) return false;
                        return !params.data?.hasChildren;
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'endDate',
                        gridApi: gridRef.current?.api,
                        cellClass: 'ag-cell-padding-none ag-cell-flex ag-cell-date',
                        validation: getOptionalDateValidation({
                            isAfterStartDate: isAfterStartDate(gridRef.current?.api, 'startDate'),
                        }),
                    }),
                    cellRenderer: DateCellRenderer,
                    cellRendererParams: (params: ICellRendererParams) => {
                        return {
                            typeOfPlan: 'base',
                            textValue: params?.api.getValue('endDate', params.node),
                            notificatorTitle: 'Даты не совпадают с чартами КСГ',
                            isVisibleNotificator: params.data?.basePlanePeriodAlert,
                            canSpread: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_BASE_PLAN'),
                        } as DateCellRendererProps;
                    },
                },
            ],
        },
        {
            headerName: 'Оперативный план',
            colId: 'operPlanDates',
            children: [
                {
                    field: 'operationalStartDate',
                    colId: 'operationalStartDate',
                    width: columnWidths.get('operationalStartDate') || 82,
                    headerName: 'Начало',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams<WorkOrWorkPosition>) => {
                        if (!params.data) return false;
                        if (isWorkPosition(params.data)) return false;
                        if (params.data?.hasChildren) return false;

                        return params.data.manualPlanning || canEditableByPredecessors(params.data.predecessors, 'S');
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalStartDate',
                        gridApi: gridRef.current?.api,
                        cellClass: 'ag-cell-padding-none ag-cell-flex ag-cell-date',
                        validation: getOptionalDateValidation({
                            isBeforeEndDate: isBeforeEndDate(gridRef.current?.api, 'operationalEndDate'),
                        }),
                    }),
                    cellRenderer: (params: ICellRendererParams) => {
                        return (
                            <NotificatorContainer
                                isVisible={params.data?.periodAlert || params.data?.overdueAlert}
                                title='Даты не совпадают с чартами КСГ'
                                notificator={
                                    <OperationalDateNotificator
                                        gridApi={params.api}
                                        data={params.data!}
                                    />
                                }
                            >
                                {params?.data?.operationalStartDate}
                            </NotificatorContainer>
                        );
                    },
                },
                {
                    field: 'operationalEndDate',
                    colId: 'operationalEndDate',
                    width: columnWidths.get('operationalEndDate') || 82,
                    headerName: 'Окончание',
                    spanHeaderHeight: true,
                    editable: (params: EditableCallbackParams) => {
                        if (!params.data) return false;
                        if (isWorkPosition(params.data)) return false;
                        if (params.data?.hasChildren) return false;

                        return params.data.manualPlanning || canEditableByPredecessors(params.data.predecessors, 'F');
                    },
                    ...COL_DEF_TEMPLATE.EDITABLE_CELLS.DATE({
                        field: 'operationalEndDate',
                        gridApi: gridRef.current?.api,
                        cellClass: 'ag-cell-padding-none ag-cell-flex ag-cell-date',
                        validation: getOptionalDateValidation({
                            isAfterStartDate: isAfterStartDate(gridRef.current?.api, 'operationalStartDate'),
                        }),
                    }),
                    cellRenderer: DateCellRenderer,
                    cellRendererParams: (params: ICellRendererParams<Work, any, TAgGridContext>) => {
                        return {
                            typeOfPlan: 'operational',
                            textValue: params?.api.getValue('operationalEndDate', params.node),
                            notificator: (
                                <OperationalDateNotificator
                                    gridApi={params.api}
                                    data={params.data!}
                                />
                            ),
                            isVisibleNotificator: params.data?.periodAlert || params.data?.overdueAlert,
                            canSpread: DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'SPREAD_OPER_PLAN'),
                        } as DateCellRendererProps;
                    },
                },
            ],
        },
        {
            field: 'humanHourCost',
            colId: 'humanHourCost',
            width: columnWidths.get('humanHourCost') || 112,
            headerName: 'Трудозатраты на ед. изм., чел-час',
            headerTooltip: 'Трудозатраты на ед. изм., чел-час',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.CUSTOM_NUMBER({
                cellClass: 'ag-cell-padding-none ag-cell-flex',
                validation: getOptionalNumberValidation(),
            }),
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <NotificatorContainer
                        isVisible={params.data?.staffNotSet}
                        title={
                            <>
                                В кабинете управления работой <br />
                                не заполнен раздел «Трудозатраты»
                            </>
                        }
                    >
                        <NumberCellRenderer {...params} />
                    </NotificatorContainer>
                );
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('humanHourCost'),
        },
        {
            field: 'humanHoursTotal',
            colId: 'humanHoursTotal',
            width: columnWidths.get('humanHoursTotal') || 112,
            headerName: 'Трудозатраты общие, чел-час',
            headerTooltip: 'Трудозатраты общие, чел-час',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'humanHoursProgressTZ',
            colId: 'humanHoursProgressTZ',
            width: columnWidths.get('humanHoursProgressTZ') || 90,
            headerName: 'Прогресс ТЗ',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс трудозатрат нарастающим итогом, чел-час',
            colId: 'humanHoursProgress',
            children: [
                {
                    field: 'humanHoursProgressPlan',
                    colId: 'humanHoursProgressPlan',
                    width: columnWidths.get('humanHoursProgressPlan') || 108,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressFact',
                    colId: 'humanHoursProgressFact',
                    width: columnWidths.get('humanHoursProgressFact') || 108,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'humanHoursProgressPercent',
                    colId: 'humanHoursProgressPercent',
                    width: columnWidths.get('humanHoursProgressPercent') || 108,
                    headerName: '% выполнения',
                    headerTooltip: 'Процент выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'machineHourCost',
            colId: 'machineHourCost',
            width: columnWidths.get('machineHourCost') || 186,
            headerName: 'Затраты машин и механизмов на ед.изм., маш-час',
            headerTooltip: 'Затраты машин и механизмов на ед.изм., маш-час',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl
                    width={212}
                    name={data.displayName}
                />
            ),
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.CUSTOM_NUMBER({
                cellClass: 'ag-cell-padding-none ag-cell-flex',
                validation: getOptionalNumberValidation(),
            }),
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <NotificatorContainer
                        isVisible={params.data?.mimNotSet}
                        title={
                            <>
                                В кабинете управления работой <br />
                                не заполнен раздел <br /> «Механизмы»
                            </>
                        }
                    >
                        <NumberCellRenderer {...params} />
                    </NotificatorContainer>
                );
            },
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('machineHourCost'),
        },
        {
            field: 'machineHoursTotal',
            colId: 'machineHoursTotal',
            width: columnWidths.get('machineHoursTotal') || 160,
            headerName: 'Затраты машин и механизмов, маш-час',
            headerTooltip: 'Затраты машин и механизмов, маш-час',
            spanHeaderHeight: true,
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl
                    width={192}
                    name={data.displayName}
                />
            ),
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'primeCost',
            colId: 'primeCost',
            width: columnWidths.get('primeCost') || 132,
            headerName: 'Себестоимость',
            headerTooltip: 'Себестоимость',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costER',
            colId: 'costER',
            width: columnWidths.get('costER') || 156,
            headerName: 'Стоимость ЕР (Дог.+ДР), руб.',
            headerTooltip: 'Стоимость ЕР (Дог.+ДР), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(
                undefined,
                9999999999.999999,
                getOptionalNumberValidation({ maxValue: 9999999999.999999 })
            ),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costER'),
        },
        {
            field: 'costTotal',
            colId: 'costTotal',
            width: columnWidths.get('costTotal') || 156,
            headerName: 'Общая стоимость (Дог.+ДР), рублей',
            headerTooltip: 'Общая стоимость (Дог.+ДР), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgress',
            colId: 'costTotalProgress',
            width: columnWidths.get('costTotalProgress') || 148,
            headerName: 'Общий прогресс по стоимости (Дог.+ДР)',
            headerTooltip: 'Общий прогресс по стоимости (Дог.+ДР)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости (Дог.+ДР) нарастающим итогом, руб',
            colId: 'costProgress',
            children: [
                {
                    field: 'costProgressPlan',
                    colId: 'costProgressPlan',
                    width: columnWidths.get('costProgressPlan') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFact',
                    colId: 'costProgressFact',
                    width: columnWidths.get('costProgressFact') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercent',
                    colId: 'costProgressPercent',
                    width: columnWidths.get('costProgressPercent') || 148,
                    headerName: '% текущего выполнения',
                    headerTooltip: 'Процент текущего выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costRemaining',
            colId: 'costRemaining',
            width: columnWidths.get('costRemaining') || 156,
            spanHeaderHeight: true,
            headerName: 'Остаток стоимости (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        profile?.futures?.reportEIS && costDoneMode
            ? {
                  field: 'costDone',
                  colId: 'costDone',
                  width: columnWidths.get('costDone') || 156,
                  spanHeaderHeight: true,
                  headerName: 'Закрыто по КС2 (Дог.+ДР), рублей',
                  editable: (params: EditableCallbackParams) => {
                      if (isWorkPosition(params.data)) return false;
                      return true;
                  },
                  ...COL_DEF_TEMPLATE.EDITABLE_CELLS.CUSTOM_NUMBER({
                      key: undefined,
                      maxValue: 9999999.999999,
                      cellClass: 'ag-cell-padding-none ag-cell-flex',
                  }),
                  cellRenderer: (params: ICellRendererParams) => {
                      return (
                          <NotificatorContainer
                              isVisible={params.data?.costDoneManually != null}
                              title='Значение было внесено вручную'
                          >
                              <NumberCellRenderer
                                  value={
                                      params.data?.costDoneManuallyCalc ||
                                      params.data?.costDoneManually ||
                                      params.data?.costDone
                                  }
                              />
                          </NotificatorContainer>
                      );
                  },
                  cellEditorParams: (params: ICellEditorParams) => {
                      const maxValue = 9999999999.999999;
                      return {
                          ...params,
                          value: params.data?.hasChildren ? params.data?.costDoneManually : params.data?.costDone,
                          validation: getOptionalNumberValidation({
                              maxValue: maxValue,
                          }),
                      };
                  },
                  valueGetter: (params: ValueGetterParams) => {
                      if (params.data?.hasChildren) {
                          return params.data.costDoneManually;
                      } else {
                          return params.data.costDone;
                      }
                  },
                  valueSetter: (params: ValueSetterParams) => {
                      if (params.data?.hasChildren) {
                          params.api.applyTransaction({
                              update: [{ ...params.data, costDoneManually: params.newValue }],
                          });
                      } else {
                          params.api.applyTransaction({
                              update: [{ ...params.data, costDone: params.newValue }],
                          });
                      }
                      return true;
                  },
              }
            : {
                  field: 'costDone',
                  colId: 'costDone',
                  width: columnWidths.get('costDone') || 156,

                  spanHeaderHeight: true,
                  headerName: 'Закрыто по КС2 (Дог.+ДР), рублей',
                  editable: (params: ValueGetterParams) => {
                      const data = params.data;
                      if (isWorkPosition(data)) return false;
                      if (data && 'hasChildren' in data) {
                          return !data.hasChildren;
                      }
                      return false;
                  },
                  ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(
                      undefined,
                      9999999999.999999,
                      getOptionalNumberValidation({ maxValue: 9999999999.999999 })
                  ),
                  ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costDone'),
              },
        {
            field: 'costNZP',
            colId: 'costNZP',
            width: columnWidths.get('costNZP') || 156,
            spanHeaderHeight: true,
            headerName: 'НЗП (Дог.+ДР), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costERGK',
            colId: 'costERGK',
            width: columnWidths.get('costERGK') || 156,
            headerName: 'Стоимость ЕР (Договор), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(
                undefined,
                9999999999.999999,
                getOptionalNumberValidation({ maxValue: 9999999999.999999 })
            ),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costERGK'),
        },
        {
            field: 'costTotalGK',
            colId: 'costTotalGK',
            width: columnWidths.get('costTotalGK') || 156,
            headerName: 'Общая стоимость (Договор), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressGK',
            colId: 'costTotalProgressGK',
            width: columnWidths.get('costTotalProgressGK') || 156,
            headerName: 'Общий прогресс по стоимости (Договор)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости (Договор) нарастающим итогом, руб',
            colId: 'costProgressPlan',
            children: [
                {
                    field: 'costProgressPlanGK',
                    colId: 'costProgressPlanGK',
                    width: columnWidths.get('costProgressPlanGK') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactGK',
                    colId: 'costProgressFactGK',
                    width: columnWidths.get('costProgressFactGK') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentGK',
                    colId: 'costProgressPercentGK',
                    width: columnWidths.get('costProgressPercentGK') || 148,
                    headerName: '% текущего выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costNZPGK',
            colId: 'costNZPGK',
            width: columnWidths.get('costNZPGK') || 156,
            spanHeaderHeight: true,
            headerName: 'НЗП (Договор), рублей',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costERSub',
            colId: 'costERSub',
            width: columnWidths.get('costERSub') || 156,
            headerName: 'Стоимость ЕР (Расчет), руб.',
            spanHeaderHeight: true,
            editable: (params: EditableCallbackParams) => {
                if (isWorkPosition(params.data)) return false;
                return !params.data?.hasChildren;
            },
            ...COL_DEF_TEMPLATE.EDITABLE_CELLS.NUMBER(
                undefined,
                9999999999.999999,
                getOptionalNumberValidation({ maxValue: 9999999999.999999 })
            ),
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costERSub'),
        },
        {
            field: 'costTotalSub',
            colId: 'costTotalSub',
            width: columnWidths.get('costTotalSub') || 156,
            headerName: 'Общая стоимость (Расчет), рублей',
            spanHeaderHeight: true,
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costTotalProgressSub',
            colId: 'costTotalProgressSub',
            width: columnWidths.get('costTotalProgressSub') || 156,
            headerName: 'Общий прогресс по стоимости (Расчет)',
            spanHeaderHeight: true,
            cellRenderer: PercentageCellRenderer,
        },
        {
            headerName: 'Прогресс стоимости (Расчет) нарастающим итогом, руб',
            colId: 'costProgressPlan',
            children: [
                {
                    field: 'costProgressPlanSub',
                    colId: 'costProgressPlanSub',
                    width: columnWidths.get('costProgressPlanSub') || 126,
                    headerName: 'План',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressFactSub',
                    colId: 'costProgressFactSub',
                    width: columnWidths.get('costProgressFactSub') || 126,
                    headerName: 'Факт',
                    cellRenderer: NumberCellRenderer,
                },
                {
                    field: 'costProgressPercentSub',
                    colId: 'costProgressPercentSub',
                    width: columnWidths.get('costProgressPercentSub') || 148,
                    headerName: '% текущего выполнения',
                    cellRenderer: PercentageCellRenderer,
                },
            ],
        },
        {
            field: 'costNZPSub',
            colId: 'costNZPSub',
            width: columnWidths.get('costNZPSub') || 156,

            spanHeaderHeight: true,
            headerName: 'НЗП (Расчет), рублей',
            cellRenderer: NumberCellRenderer,
        },
        ...getOfferColDefs('reportingPeriodInfo', isOfferActive),
        {
            field: 'prStart',
            colId: 'prStart',
            width: columnWidths.get('prStart') || 148,
            spanHeaderHeight: true,
            headerName: 'Ориентировочная дата начала поставки',
            headerComponent: (data: IHeaderParams<Work, any>) => (
                <HeaderNameEl
                    width={data.column.getActualWidth()}
                    name={data.displayName}
                />
            ),
            cellRenderer: (params: ICellRendererParams<Work, any, TAgGridContext>) => {
                if (!params.data) return <></>;

                return (
                    <div>
                        <Link
                            style={{
                                textDecoration: 'underline',
                            }}
                            to={`/ksg/${params.context.projectId}/mto/${params.data?.id}`}
                        >
                            {params.data.mtoExists && !params.data.prProvision ? params.data.prStart : null}
                        </Link>
                    </div>
                );
            },
        },
        {
            field: 'prEnd',
            colId: 'prEnd',
            width: columnWidths.get('prEnd') || 148,
            spanHeaderHeight: true,
            headerName: 'Ориентировочная дата завершения поставки',
            cellRenderer: (params: ICellRendererParams<Work, any, TAgGridContext>) => {
                if (!params.data) return <></>;
                return (
                    <div>
                        <Link
                            style={{
                                textDecoration: 'underline',
                            }}
                            to={`/ksg/${params.context.projectId}/mto/${params.data.id}`}
                        >
                            {params.data.prProvision !== null && params.data.prProvision >= 100 ? null : params.value}
                        </Link>
                    </div>
                );
            },
        },
        {
            field: 'prProvision',
            colId: 'prProvision',
            width: columnWidths.get('prProvision') || 124,
            spanHeaderHeight: true,
            headerName: 'Обеспеченность в %',
            cellRenderer: (params: ICellRendererParams<Work, any, TAgGridContext>) => {
                if (!params.data) return <></>;
                return (
                    <div>
                        {params.value !== null ? (
                            <HtmlTooltip title={tooltipHelperNumber(params.value)}>
                                <Link
                                    style={{
                                        textDecoration: 'underline',
                                    }}
                                    to={`/ksg/${params.context.projectId}/mto/${params.data.id}`}
                                >
                                    <NumericFormat
                                        decimalScale={2}
                                        suffix={'%'}
                                        displayType={'text'}
                                        value={params.value}
                                        thousandSeparator={' '}
                                        decimalSeparator=','
                                    />
                                </Link>
                            </HtmlTooltip>
                        ) : (
                            <>
                                {params.data.mtoExists ? (
                                    <Link
                                        style={{
                                            textDecoration: 'underline',
                                        }}
                                        to={`/ksg/${params.context.projectId}/mto/${params.data.id}`}
                                    >
                                        0%
                                    </Link>
                                ) : (
                                    <div></div>
                                )}
                            </>
                        )}
                    </div>
                );
            },
        },
        // {
        //     field: 'workPeriod',
        //     colId: 'workPeriod',
        //     width: columnWidths.get('workPeriod') || 100,
        //     spanHeaderHeight: true,
        //     headerName: 'Период работ',
        //     cellRenderer: (cellRendererParams: ICellRendererParams) => {
        //         if ('isWorkPosition' in cellRendererParams.data) return null
        //         return <InfoWorkPeriod {...cellRendererParams} />
        //     },
        // },
        // Добавлена колонка для общего показателя верификационного факта
        {
            field: 'verifiedFactTotal',
            colId: 'verifiedFactTotal',
            width: columnWidths.get('verifiedFactTotal') || 132,
            spanHeaderHeight: true,
            headerName: 'Вер. факт нарастающим итогом',
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'workBonds',
            colId: 'workBonds',
            width: columnWidths.get('workBonds') || 80,
            spanHeaderHeight: true,
            headerName: 'Связи',
            cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, TAgGridContext>) => {
                if (isWorkPosition(params.data)) return null;

                const data = params.data as Work;

                return (
                    <div>
                        {!data?.hasChildren && (
                            <Stack
                                onClick={() => {
                                    if (DOES_ROLE_HAS_ACCESS_TO_FEATURE(profile.role, 'CREATE_LINK_GANT_SMR')) {
                                        setRelationsOpened(true);
                                        setRelationData({
                                            workName: data?.workName!,
                                            startDate: data?.workPeriod?.start || null,
                                            endDate: data?.workPeriod?.end || null,
                                            id: data!.id,
                                        });
                                    }
                                }}
                                direction='row'
                                gap='8px'
                            >
                                <p>{params.value?.count}</p>
                                <RelationsIconNew />
                            </Stack>
                        )}
                    </div>
                );
            },
        },
    ];

    return isVisibleGantt
        ? [
              {
                  headerName: '',
                  field: 'actions',
                  width: columnWidths.get('actions') || 50,
                  spanHeaderHeight: true,
                  cellRenderer: (params: any) => (
                      <IconButton
                          size='small'
                          onMouseDown={(e) => {
                              e.preventDefault();
                              let startY = e.clientY;
                              const onMouseMove = (moveEvent: MouseEvent) => {
                                  const deltaY = moveEvent.clientY - startY;
                                  startY = moveEvent.clientY;
                                  if (handleHeightChange) handleHeightChange(params.node.rowIndex, deltaY);
                              };
                              const onMouseUp = () => {
                                  document.removeEventListener('mousemove', onMouseMove);
                                  document.removeEventListener('mouseup', onMouseUp);
                              };
                              document.addEventListener('mousemove', onMouseMove);
                              document.addEventListener('mouseup', onMouseUp);
                          }}
                      >
                          <HeightIcon sx={{ width: 16, height: 16 }} />
                      </IconButton>
                  ),
              },
              {
                  headerName: 'Режим задачи',
                  field: 'mode',
                  width: columnWidths.get('mode') || 85,
                  spanHeaderHeight: true,
                  cellRenderer: TaskModeCell,
                  cellRendererParams: (params: ICellRendererParams<WorkOrWorkPosition, any, TAgGridContext>) => ({
                      projectId: params.context.projectId,
                  }),
              },
              ...dataColumn,
          ]
        : [
              {
                  headerName: '',
                  field: 'actions',
                  width: columnWidths.get('actions') || 50,
                  spanHeaderHeight: true,
                  cellRenderer: (params: any) => (
                      <IconButton
                          size='small'
                          onMouseDown={(e) => {
                              e.preventDefault();
                              let startY = e.clientY;
                              const onMouseMove = (moveEvent: MouseEvent) => {
                                  const deltaY = moveEvent.clientY - startY;
                                  startY = moveEvent.clientY;
                                  if (handleHeightChange) handleHeightChange(params.node.rowIndex, deltaY);
                              };
                              const onMouseUp = () => {
                                  document.removeEventListener('mousemove', onMouseMove);
                                  document.removeEventListener('mouseup', onMouseUp);
                              };
                              document.addEventListener('mousemove', onMouseMove);
                              document.addEventListener('mouseup', onMouseUp);
                          }}
                      >
                          <HeightIcon sx={{ width: 16, height: 16 }} />
                      </IconButton>
                  ),
              },
              ...dataColumn,
              {
                  field: 'planFact',
                  width: columnWidths.get('planFact') || 96,
                  spanHeaderHeight: true,
                  headerName: 'План/Факт',
                  cellRenderer: (params: ICellRendererParams<WorkOrWorkPosition, any, any>) => {
                      if (isWorkPosition(params.data)) return null;

                      const data = params.data as Work;

                      return (
                          <RowMonthHeaderRenderer
                              isGant={hasParam('g', location.search) || hasParam('gf', location.search)}
                              hasChildren={data?.hasChildren}
                              cellRendererParams={params}
                          />
                      );
                  },
                  cellClass: 'ag-cell-padding-none ag-cell-flex',
              },
              ...getHeadersMonths(
                  headersCPG.data?.allHeaders,
                  (data, index, doesThisMonthCurrent) => {
                      if (!data?.data?.monthlyCharts) return null;

                      return (
                          <MonthRenderer
                              headers={headersCPG.data}
                              cellRendererParams={data}
                              setMutationsLoading={setMutationsLoading}
                              chartIndex={index}
                              isActive={doesThisMonthCurrent}
                          />
                      );
                  },
                  columnWidths
              ),
          ];
};
