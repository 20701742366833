import { isCompany } from './config.types';

interface ConfigData {
    appHost: string[] | string; //without slash at the end
    apiHost: string; //without slash at the end
    coreURL: string; //without slash at the end
}

interface Config {
    server: string;
    core: string;
}

const subDomain = window.location.host.split('.')[0];
export const currentCompanyName = isCompany(subDomain) ? subDomain : null;

const data: ConfigData[] = [
    {
        appHost: 'https://dev.rm.pragma.info',
        apiHost: 'https://dev.api.rm.pragma.info',
        coreURL: 'https://dev.core.pragma.info',
    },
    {
        appHost: 'https://rm.pragma.info',
        apiHost: 'https://api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://most.rm.pragma.info',
        apiHost: 'https://most.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://eks.rm.pragma.info',
        apiHost: 'https://eks.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://nn.rm.pragma.info',
        apiHost: 'https://nn.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://oes.rm.pragma.info',
        apiHost: 'https://oes.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://ap.rm.pragma.info',
        apiHost: 'https://ap.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://ig.rm.pragma.info',
        apiHost: 'https://ig.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://energomost.rm.pragma.info',
        apiHost: 'https://energomost.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://sti.rm.pragma.info',
        apiHost: 'https://sti.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://rzd.rm.pragma.info',
        apiHost: 'https://rzd.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://zs.rm.pragma.info',
        apiHost: 'https://zs.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://cg.rm.pragma.info',
        apiHost: 'https://cg.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://rgs.rm.pragma.info',
        apiHost: 'https://rgs.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://si.rm.pragma.info',
        apiHost: 'https://si.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://geo.rm.pragma.info',
        apiHost: 'https://geo.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://su555.rm.pragma.info',
        apiHost: 'https://su555.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://gcsu-555.rm.pragma.info',
        apiHost: 'https://gcsu-555.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://rusteko.rm.pragma.info',
        apiHost: 'https://rusteko.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://dml.rm.pragma.info',
        apiHost: 'https://dml.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://kvant.rm.pragma.info',
        apiHost: 'https://kvant.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://krno.rm.pragma.info',
        apiHost: 'https://krno.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://tg.rm.pragma.info',
        apiHost: 'https://tg.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://rsk.rm.pragma.info',
        apiHost: 'https://rsk.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://blacksea.rm.pragma.info',
        apiHost: 'https://blacksea.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://samolet.rm.pragma.info',
        apiHost: 'https://samolet.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://inarctica.rm.pragma.info',
        apiHost: 'https://inarctica.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://pelisker.rm.pragma.info',
        apiHost: 'https://pelisker.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://ezois-ural.rm.pragma.info',
        apiHost: 'https://ezois-ural.api.rm.pragma.info',

        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://soyuzdonstroy.rm.pragma.info',
        apiHost: 'https://soyuzdonstroy.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://gsp.rm.pragma.info',
        apiHost: 'https://gsp.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
    {
        appHost: 'https://psm.rm.pragma.info',
        apiHost: 'https://psm.api.rm.pragma.info',
        coreURL: 'https://core.pragma.info',
    },
];

const currentConfig = data.find((v) => {
    const appHosts = Array.isArray(v.appHost) ? v.appHost : [v.appHost];
    return appHosts.includes(document.location.origin);
});

export const deployLinks: Config = {
    server: currentConfig?.apiHost ?? 'https://dev.api.rm.pragma.info',
    core: currentConfig?.coreURL ?? 'https://dev.core.pragma.info',
};
