import { FilterAlt } from '@mui/icons-material';
import {
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    IconButton,
    Paper,
    Popper,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { FC, RefObject, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDidMountEffect } from '@/hooks/useDidMountEffect';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { StyledBtn } from '@/pages/WorkManagment/components/components.styles';

import { clearWorks } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { filtersKsgUnlimitedI, filtersSelector, handleChangeWorkFilter } from '@/store/slices/filtersSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import { theme } from '@/styles/theme';

import { ActiveFilterNotificator } from './WorkFilter.styles';
import { IWorkFilterProps } from './WorkFilter.types';
import { workFilterOptions as options } from './WorkFilters.config';

export const WorkFilter: FC<IWorkFilterProps> = () => {
    const { filters } = useTypedSelector(filtersSelector);
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { projectId } = useParams();
    const buttonRef = useRef() as RefObject<HTMLButtonElement>;

    useDidMountEffect(() => {
        resetWorkFilter();
    }, [projectId]);

    const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        dispatch(clearWorks());
        dispatch(handleChangeWorkFilter(value as filtersKsgUnlimitedI['workFilter']));
    };

    const resetWorkFilter = () => dispatch(handleChangeWorkFilter(null));
    const handleReset = () => resetWorkFilter();

    const handleToggle = () => setIsOpen((prev) => !prev);
    const handleClose = () => setIsOpen(() => false);

    return (
        <ClickAwayListener onClickAway={handleClose}>
            <FlexRowWrapper ref={buttonRef}>
                <IconButton
                    onClick={handleToggle}
                    sx={{
                        color: 'white',
                        position: 'relative',
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: '6px',
                    }}
                    disableRipple
                >
                    {filters.workFilter && <ActiveFilterNotificator />}
                    <FilterAlt />
                </IconButton>

                <Popper
                    open={isOpen}
                    anchorEl={buttonRef.current}
                    sx={{ zIndex: 99999 }}
                    placement='bottom-end'
                >
                    <Paper elevation={8}>
                        <FlexColumnWrapper
                            p={2}
                            bgcolor={'white'}
                            borderRadius={0.75}
                            pb={0.75}
                        >
                            <FlexRowWrapper>
                                <Typography
                                    fontSize={16}
                                    fontWeight={500}
                                >
                                    Фильтр по статусу работ
                                </Typography>
                                <StyledBtn
                                    variant='outlined'
                                    size='small'
                                    disabled={!filters.workFilter}
                                    onClick={handleReset}
                                >
                                    Сбросить фильтр
                                </StyledBtn>
                            </FlexRowWrapper>
                            <FlexColumnWrapper gap={0}>
                                {options.map((option) => {
                                    return (
                                        <FormControl key={option.value}>
                                            <RadioGroup
                                                onChange={handleChange}
                                                value={filters.workFilter}
                                            >
                                                <FormControlLabel
                                                    value={option.value}
                                                    control={
                                                        <Radio
                                                            size='small'
                                                            sx={{ p: 1.25 }}
                                                        />
                                                    }
                                                    label={<Typography>{option.label}</Typography>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    );
                                })}
                            </FlexColumnWrapper>
                        </FlexColumnWrapper>
                    </Paper>
                </Popper>
            </FlexRowWrapper>
        </ClickAwayListener>
    );
};
