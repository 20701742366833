import { Box } from '@mui/material';
import { GridApi } from 'ag-grid-community';
import { differenceInDays, parse } from 'date-fns';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSpinDelay } from 'spin-delay';

import { Work } from '@/api/ksg/ksg.def';
import {
    useNotificationsIncreasePeriodMutation,
    useNotificationsIncreaseVolumeMutation,
} from '@/api/notificationsCenter/notificationsCenter.api';
import { INotification } from '@/api/notificationsCenter/notificationsCenter.def';

import { req } from '@/pages/WorkManagment/api/api';

import { onEditAgKsg, updateKsgWorksCriticalPath } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { useAppDispatch } from '@/store/store';

import { AlertIcon } from '../Notificator';
import { Tooltip } from './OperationalDateNotificator.styles';
import { TooltipContent } from './TooltipContent';

interface IProps {
    data: Work;
    gridApi: GridApi;
}

export const OperationalDateNotificator: FC<IProps> = ({ gridApi, data }) => {
    const { projectId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [increasePeriod, { isLoading: isLoadingIncreasePeriod }] = useNotificationsIncreasePeriodMutation();
    const [increaseVolume, { isLoading: isLoadingIncreaseVolume }] = useNotificationsIncreaseVolumeMutation();
    const { t } = useTranslation('mutations');
    const dispatch = useAppDispatch();

    const workId = data.id;

    const isSubmitLoading = isLoadingIncreaseVolume || isLoadingIncreasePeriod;
    const [isNotificationLoading, setIsNotificationLoading] = useState<boolean>(false);
    const [notification, setNotification] = useState<INotification | null>(null);

    const isOverdueAlertLoading = useSpinDelay(isNotificationLoading, { delay: 0, minDuration: 400 });

    const fetchNotification = async () => {
        if (!data.overdueAlert) return;
        if (isSubmitLoading) return;

        setIsNotificationLoading(!notification);
        try {
            const response = await req.get(`/projects/${projectId}/alerts/ksg/${workId}/get`);
            setNotification(response.data as INotification);
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsNotificationLoading(false);
        }
    };

    const handleVolumeIncrease = async () => {
        if (!notification) return;
        if (isLoadingIncreaseVolume) return;

        try {
            const response = await increaseVolume({
                projectId: Number(projectId),
                body: { alertIds: [notification.alertId] },
            }).unwrap();
            const updatedWorks = response.data as Work[];
            dispatch(onEditAgKsg(updatedWorks));
            dispatch(updateKsgWorksCriticalPath(response.criticalPath));
            gridApi.applyTransaction({ update: updatedWorks });

            if (response.success) {
                enqueueSnackbar(t('notifications_volume_increased'), {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(response.description, {
                    variant: 'error',
                });
            }
        } catch (error) {
            console.log('error', error);
            enqueueSnackbar('Ошибка', {
                variant: 'error',
            });
        }
    };

    const handlePeriodIncrease = async () => {
        if (!notification) return;
        if (isLoadingIncreasePeriod) return;

        try {
            const response = await increasePeriod({
                projectId: Number(projectId),
                body: { alertIds: [notification.alertId] },
            }).unwrap();
            const updatedWorks = response.data as Work[];
            dispatch(onEditAgKsg(updatedWorks));
            dispatch(updateKsgWorksCriticalPath(response.criticalPath));
            gridApi.applyTransaction({ update: updatedWorks });

            enqueueSnackbar(t('notifications_period_increased'), {
                variant: 'success',
            });
        } catch (error) {
            console.log('error', error);
            enqueueSnackbar('Ошибка', {
                variant: 'error',
            });
        }
    };

    const getDiffDays = () => {
        if (!data.operationalEndDate) return 0;
        if (!notification) return 0;
        if (!notification.dateDone) return 0;

        const operationalEndDate = parse(data.operationalEndDate as string, 'dd.MM.yyyy', new Date());
        const dateDone = parse(notification.dateDone as string, 'dd.MM.yyyy', new Date());

        return differenceInDays(operationalEndDate, dateDone);
    };

    return (
        <Tooltip
            title={
                <TooltipContent
                    days={Math.abs(getDiffDays())}
                    isPeriodAlert={data.periodAlert}
                    isOverdueAlert={data.overdueAlert}
                    isAvailableIncreaseVolume={notification?.availableInc.volume}
                    isAvailableIncreasePeriod={notification?.availableInc.period}
                    isIncreaseVolumeDisabled={isLoadingIncreaseVolume}
                    isIncreasePeriodDisabled={isLoadingIncreasePeriod}
                    isOverdueAlertLoading={isOverdueAlertLoading}
                    onVolumeIncrease={handleVolumeIncrease}
                    onPeriodIncrease={handlePeriodIncrease}
                    fetchNotification={fetchNotification}
                />
            }
        >
            <Box>
                <AlertIcon color={data.overdueAlert ? '#FE9B3F' : undefined} />
            </Box>
        </Tooltip>
    );
};
