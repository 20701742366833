import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Stack } from '@mui/material';
import { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { Link } from 'react-router-dom';

import { Work } from '@/api/ksg/ksg.def';

import { OverflowableTypographyWithTooltip } from '@/components/OverflowableTypographyWithTooltip/OverflowableTypographyWithTooltip';
import { UIFolder } from '@/components/ui/UIFolder';

import { WorkPositionLevelRenderer } from '@/pages/AgGrid/components/WorkPositionLevelRenderer';
import { COL_DEF_TEMPLATE } from '@/pages/WorkManagment/components/AgGridService/AgGridColumnDef.service';
import { NumberCellRenderer } from '@/pages/WorkManagment/components/CellRenderers/NumberCellRenderer/NumberCellRenderer';

import { TextCellRenderer } from './AgGridKsgKC6A.components';
import { IGetKC6AColDefsProps, TKC6AColDefs, TKC6AWork, TKC6AWorkOrPosition } from './AgGridKsgKC6A.def';
import { getHeaders } from './AgGridKsgKC6A.utils';

export const getKC6AColDefs = ({
    hiddenRows,
    projectId,
    pathname,
    headers,
    updateHiddenRows,
}: IGetKC6AColDefsProps): TKC6AColDefs => {
    const data = [
        {
            headerName: '',
            field: 'level',
            width: 130,
            colId: 'level',
            spanHeaderHeight: true,
            cellRenderer: (params: ICellRendererParams<TKC6AWorkOrPosition, any, any>) => {
                if (!params.data) return <></>;

                if ('isPosition' in params.data) return <WorkPositionLevelRenderer />;

                const data = params.data as TKC6AWork;
                const level = data.level;

                const renderArrow = () => {
                    if (!data.hasChildren && !data.positions) return null;

                    const checked = hiddenRows.find((v) => v.code === data.code && v.workId === data.workId)
                        ? true
                        : false;

                    return (
                        <IconButton
                            sx={{
                                padding: 0,
                            }}
                            component='button'
                            onClick={() =>
                                updateHiddenRows(
                                    {
                                        workId: data.workId,
                                        code: data.code,
                                    },
                                    checked
                                )
                            }
                        >
                            {checked ? (
                                <KeyboardArrowUpIcon
                                    sx={{
                                        width: 16,
                                        height: 16,
                                    }}
                                />
                            ) : (
                                <KeyboardArrowDownIcon
                                    sx={{
                                        width: 16,
                                        height: 16,
                                    }}
                                />
                            )}
                        </IconButton>
                    );
                };

                return (
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={1}
                    >
                        {renderArrow()}
                        <UIFolder
                            level={level}
                            hasChildren={data?.hasChildren!}
                        />
                    </Stack>
                );
            },
        },
        {
            field: 'numOrder',
            colId: 'numOrder',
            width: 130,
            spanHeaderHeight: true,
            headerName: '№',
            cellRenderer: (data: ICellRendererParams<Work, any, any>) => {
                return <div>{data.value}</div>;
            },
        },
        {
            field: 'code',
            colId: 'code',
            width: 130,
            spanHeaderHeight: true,
            headerName: 'ID',
            valueGetter: (params: ValueGetterParams<TKC6AWorkOrPosition>) => params.data?.workId,
            cellRenderer: (params: ICellRendererParams<TKC6AWorkOrPosition, any, any>) => {
                if (params.data && 'isPosition' in params.data)
                    return (
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1 } }}
                        >
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    );

                const data = params.data as TKC6AWork;

                return (
                    <Link
                        to={`/workManagment/${projectId}/${data?.workId}/estimate/resources`}
                        state={{ prevPath: pathname }}
                    >
                        <OverflowableTypographyWithTooltip
                            maxRows={3}
                            TypographyProps={{ sx: { px: 1, cursor: 'pointer' } }}
                        >
                            {params.value}
                        </OverflowableTypographyWithTooltip>
                    </Link>
                );
            },
        },
        {
            field: 'name',
            colId: 'name',
            width: 260,
            headerName: 'Наименование работы',
            spanHeaderHeight: true,
            editable: false,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('name'),
            cellRenderer: TextCellRenderer,
        },
        {
            field: 'objTitle',
            colId: 'objTitle',
            width: 130,
            headerName: 'Титул',
            spanHeaderHeight: true,
            editable: false,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objTitle'),
            cellRenderer: TextCellRenderer,
        },
        {
            field: 'objName',
            colId: 'objName',
            width: 130,
            headerName: 'Объект',
            spanHeaderHeight: true,
            editable: false,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('objName'),
            cellRenderer: TextCellRenderer,
        },
        {
            headerName: 'Шифры ЛСР',
            colId: 'lsrCiphers',
            children: [
                {
                    field: 'lsr',
                    width: 130,
                    headerName: 'Шифр ЛСР',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: TextCellRenderer,
                },
                {
                    field: 'chapter',
                    width: 130,
                    headerName: 'Раздел',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: TextCellRenderer,
                },
                {
                    field: 'header',
                    width: 130,
                    headerName: 'Заголовок',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: TextCellRenderer,
                },
            ],
        },
        {
            field: 'unit',
            colId: 'unit',
            width: 130,
            headerName: 'Единица измерения',
            headerTooltip: 'Единица измерения',
            spanHeaderHeight: true,
            editable: false,
            cellRenderer: TextCellRenderer,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('unit'),
        },
        {
            field: 'volumeTotal',
            colId: 'volumeTotal',
            width: 130,
            headerName: 'Общий объем',
            spanHeaderHeight: true,
            editable: false,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('volumeTotal'),
            cellRenderer: NumberCellRenderer,
        },
        {
            field: 'costEr',
            colId: 'costEr',
            width: 130,
            headerName: 'Стоимость ЕР',
            spanHeaderHeight: true,
            editable: false,
            ...COL_DEF_TEMPLATE.WITH_GETTER_AND_SETTER('costEr'),
            cellRenderer: NumberCellRenderer,
        },
        ...getHeaders(headers),
        {
            headerName: 'Выполнено за период',
            colId: 'executedAtPeriod',
            children: [
                {
                    field: 'volumeAtPeriod',
                    width: 130,
                    headerName: 'Объем',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<TKC6AWork>) => params.data?.currentAct?.volumeAtPeriod,
                },
                {
                    field: 'costAtPeriod',
                    width: 130,
                    headerName: 'Стоимость',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<TKC6AWork>) => params.data?.currentAct?.costAtPeriod,
                },
            ],
        },
        {
            headerName: 'На конец отчетного периода',
            colId: 'atEndOfReportingPeriod',
            children: [
                {
                    field: 'volumeAtEndPeriod',
                    width: 130,
                    headerName: 'Объем',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<TKC6AWork>) => params.data?.currentAct?.volumeAtEndPeriod,
                },
                {
                    field: 'costAtEndPeriod',
                    width: 130,
                    headerName: 'Стоимость',
                    editable: false,
                    spanHeaderHeight: true,
                    cellRenderer: NumberCellRenderer,
                    valueGetter: (params: ValueGetterParams<TKC6AWork>) => params.data?.currentAct?.costAtEndPeriod,
                },
            ],
        },
    ];

    return data;
};
