import styled from 'styled-components';

import { FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';

export const ActiveFilterNotificator = styled(FlexRowWrapper)`
    background: ${({ theme }) => theme.palette.error.main};
    border: ${({ theme }) => `2px solid ${theme.palette.bg.gray}`};
    border-radius: 100%;
    width: 12px;
    height: 12px;
    justify-content: center;
    top: 0;
    right: 0;
    position: absolute;
    width: 1rem !important;
    height: 1rem !important;
    top: -3px !important;
    right: -3px !important;
`;
