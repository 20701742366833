import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { ICustomCellEditorParams } from '@/pages/WorkManagment/components/CellEditors/CellEditor/CellEditor.def';

type TGetOptionalNumberValidationProps = {
    maxValue?: number;
    customValidateMaxValue?: (v: any) => any;
};

const validateMaxValue =
    (maxValue = 9999) =>
    (value: any) => {
        if (isNull(value) || isUndefined(value)) return true;
        return parseFloat(value) <= maxValue || `Макс. ${maxValue}`;
    };

export const getOptionalNumberValidation = ({
    maxValue,
    customValidateMaxValue,
}: TGetOptionalNumberValidationProps = {}): ICustomCellEditorParams['validation'] => {
    return {
        required: false,
        validate: {
            maxValue: customValidateMaxValue ?? validateMaxValue(maxValue),
        },
    };
};
