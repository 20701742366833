import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Button, Checkbox, CircularProgress, FormControlLabel, Stack, Typography } from '@mui/material';
import { FaFilter } from '@react-icons/all-files/fa/FaFilter';
import React, { useMemo, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSpinDelay } from 'spin-delay';

import {
    queryTermsRequestType,
    queryTermsType,
    useGetFilterListMsgUnlimitedQuery,
    useGetFilterListUnlimitedQuery,
} from '@/api/filters/filters.api';

import { FlexColumnWrapper, FlexRowWrapper } from '@/pages/NewExecutorView/components/components.styles';
import { StyledTooltip } from '@/pages/WorkManagment/components/components.styles';

import InfoSvg from '@/shared/SVG/InfoSvg';

import { agGridListMode, changeListMode } from '@/store/slices/agGridKsgMsgSlices/agGridKsgSlice';
import { newDate } from '@/store/slices/cpgViewSlice';
import {
    FilterLevels,
    filtersSelector,
    handleChangeUndistributedBaseVolume,
    handleChangeUndistributedOperationalVolume,
    levelsUnlimitedI,
    refreshFilters,
} from '@/store/slices/filtersSlice';
import { monthMsgSelector } from '@/store/slices/monthMsgSlice';
import { useAppDispatch, useTypedSelector } from '@/store/store';

import s from './CheckboxPopover.module.scss';
import CheckboxesPart, { customPropertiesByLevelFilters } from './CheckboxexPart';

type TShowEmptyProps = {
    label?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

export const ShowEmptyCheckbox: React.FC<TShowEmptyProps> = ({ label, checked, onChange }) => {
    return (
        <FlexRowWrapper justifyContent={'space-between'}>
            <FormControlLabel
                label={label || 'Показать пустые'}
                sx={{
                    '.MuiSvgIcon-root': {
                        fill: '#0044B4',
                    },
                }}
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            onChange(e?.target?.checked);
                        }}
                    />
                }
            />

            <StyledTooltip
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: 'rgba(151, 162, 181,0.85)',
                            maxWidth: 220,
                            fontSize: 12,
                        },
                    },
                }}
                title={
                    <Typography
                        fontSize={12}
                        color={'#fff'}
                    >
                        Будут показаны работы <br />
                        с незаполненной группой работ <br /> и базовыми датами
                    </Typography>
                }
            >
                <div>
                    <InfoSvg
                        viewBox='0 0 20 20'
                        sx={{ color: '#668FD2', fontSize: '20px', cursor: 'pointer' }}
                    />
                </div>
            </StyledTooltip>
        </FlexRowWrapper>
    );
};

type TListModeCheckboxProps = {};

export const ListModeCheckbox: React.FC<TListModeCheckboxProps> = () => {
    const listMode = useTypedSelector(agGridListMode);
    const dispatch = useAppDispatch();

    return (
        <FormControlLabel
            label={'Списком'}
            sx={{
                '.MuiSvgIcon-root': {
                    fill: '#0044B4',
                },
            }}
            control={
                <Checkbox
                    checked={listMode}
                    onChange={(e) => dispatch(changeListMode(e.target.checked))}
                />
            }
        />
    );
};

interface IProps {
    title: string;
}

interface IHeaderProps {
    title: string;
    onFiltersReset: () => void;
}

const UIHeader: React.FC<IHeaderProps> = ({ title, onFiltersReset }) => {
    return (
        <div className={s.top}>
            <div className={s.filterTitle}>
                <FaFilter color='#0044b4' />
                <h4>{title}</h4>
            </div>
            <Button
                sx={{
                    color: '#7890B2',
                    padding: '0.1rem 0.5rem',
                    fontWeight: '400',
                }}
                onClick={onFiltersReset}
            >
                Сбросить фильтр
            </Button>
        </div>
    );
};

export const TotalVolumeFilters: React.FC<IProps> = ({ title }) => {
    const dispatch = useDispatch();
    const { filters } = useTypedSelector(filtersSelector);

    const handleFiltersReset = () => {
        dispatch(refreshFilters());
        dispatch(changeListMode(false));
    };

    return (
        <main className={s.main}>
            <div className={s.top_section}>
                <UIHeader
                    title={'Общий объём'}
                    onFiltersReset={handleFiltersReset}
                />

                <Stack gap={'8px'}>
                    <FormControlLabel
                        label={
                            <>
                                Работы с неполным распределением: <br /> Базовый план
                            </>
                        }
                        sx={{
                            gap: '8px',
                            marginRight: 0,
                            marginLeft: '-6px',

                            '.MuiSvgIcon-root': {
                                fill: '#0044B4',
                            },
                        }}
                        control={
                            <Checkbox
                                checked={filters.undistributedBaseVolume}
                                onChange={(e) => dispatch(handleChangeUndistributedBaseVolume(e?.target?.checked))}
                                sx={{
                                    padding: '3px',
                                }}
                            />
                        }
                    />
                    <FormControlLabel
                        label={
                            <>
                                Работы с неполным распределением: <br /> Оперативный план
                            </>
                        }
                        sx={{
                            gap: '8px',
                            marginRight: 0,
                            marginLeft: '-6px',

                            '.MuiSvgIcon-root': {
                                fill: '#0044B4',
                            },
                        }}
                        control={
                            <Checkbox
                                checked={filters.undistributedOperationalVolume}
                                onChange={(e) =>
                                    dispatch(handleChangeUndistributedOperationalVolume(e?.target?.checked))
                                }
                                sx={{
                                    padding: '3px',
                                }}
                            />
                        }
                    />
                </Stack>
            </div>
        </main>
    );
};

// TODO:
// 1. Дать осмысленное название
export default function CheckboxPopover({ msg }: { msg?: boolean }) {
    const [inputValue, setInputValue] = useState('');
    const { queryTerm, filters } = useTypedSelector(filtersSelector);
    const { month } = useTypedSelector(monthMsgSelector);

    const { projectId } = useParams();
    const dispatch = useDispatch();

    const termToReqTerm: Record<queryTermsType, queryTermsRequestType> = {
        contractorCompany: 'contractor',
        objName: 'obj',
        objTitle: 'title',
        rdCode: 'rd',
        rdStatus: 'status',
        workName: 'work',
        workGroup: 'group',
    };

    const filtersQueryKsgUnlimited = useGetFilterListUnlimitedQuery(
        {
            id: Number(projectId),
            term: termToReqTerm[queryTerm],
            filters: filters,
        },
        {
            skip: msg,
        }
    );

    const filtersQueryMsgUnlimited = useGetFilterListMsgUnlimitedQuery(
        {
            id: Number(projectId),
            term: termToReqTerm[queryTerm],
            filters: filters,
            year: newDate(month).getFullYear(),
            month: newDate(month).getMonth() + 1,
        },
        {
            skip: !msg,
        }
    );

    const filtersQuery = msg ? filtersQueryMsgUnlimited : filtersQueryKsgUnlimited;
    const isLoadingWithMinDuration = useSpinDelay(filtersQuery.isLoading, { delay: 0, minDuration: 700 });

    const filteredObject = useMemo(() => {
        const obj = filtersQuery.data?.data;
        if (!obj) return;

        return Object.entries(obj).reduce((acc: levelsUnlimitedI, [key, valuesArr]) => {
            const filteredArray = (valuesArr as string[]).filter((filt) =>
                filt.toLowerCase().includes(inputValue.toLowerCase())
            );
            if (!!filteredArray.length) {
                acc[key as FilterLevels] = filteredArray;
            }
            return acc;
        }, {});
    }, [filtersQuery.data?.data, inputValue]);

    const getTitle = (term: queryTermsType) => {
        const titlesRus: { [key: string]: string } = {
            objTitle: 'Титул',
            objName: 'Название объекта',
            rdCode: 'Шифр РД',
            rdStatus: 'Статус выдачи РД',
            contractorCompany: 'Подрядчик',
            workName: 'Наименование ',
            workGroup: 'Группа работ',
            brigades: 'Бригада',
            volumeTotal: 'Общий объем',
        };
        return titlesRus[term];
    };

    const handleFiltersReset = () => {
        dispatch(refreshFilters());
        dispatch(changeListMode(false));
    };

    if (isLoadingWithMinDuration) {
        return (
            <Stack
                minHeight={'200px'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <CircularProgress />
            </Stack>
        );
    }

    return (
        <main className={s.main}>
            <div className={s.top_section}>
                <UIHeader
                    title={getTitle(queryTerm)}
                    onFiltersReset={handleFiltersReset}
                />

                <div className={s.input}>
                    <DebounceInput
                        value={inputValue}
                        placeholder='Поиск'
                        debounceTimeout={300}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <span>
                        <SearchOutlinedIcon
                            sx={{
                                color: '#7890B2',
                            }}
                        />
                    </span>
                </div>
            </div>

            <div className={s.checkboxes}>
                <ListModeCheckbox />

                {filteredObject && (
                    <FlexColumnWrapper gap={0}>
                        {Object.entries(filteredObject).map(([key, value]) => (
                            <CheckboxesPart
                                key={`${key} ${value}`}
                                level={key as FilterLevels}
                                arr={value}
                                color={customPropertiesByLevelFilters[key as FilterLevels].color}
                            />
                        ))}
                    </FlexColumnWrapper>
                )}
            </div>
        </main>
    );
}
